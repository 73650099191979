import React from "react";
import logo from "../../assets/logo.svg";

class RegisAMobileMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firsttime: true,
      idCard: "",
      checkUseAMobile: false,
    };
  }

  setuseAMobile = (evt) => {
    let data = !this.state.checkUseAMobile;
    this.setState({
      checkUseAMobile: data,
    });
  };
  setIdCard = (evt) => {
    let { value } = evt.target;
    if (this.state.firsttime) {
      this.setState({
        firsttime: false,
      });
    }
    // console.log(evt);
    const re = /^[0-9\b]+$/;
    if (value.length <= 13 && (value === "" || re.test(value))) {
      this.setState({
        idCard: value,
      });
    }
  };
  setName = (text) => {
    this.props.goTo(text);
  };

  componentDidMount() {}
  render() {
    let { idCard, checkUseAMobile, firsttime } = this.state;
    return (
      <div className="RegisAMobileMember-main w-100 pt-5 bg-warning p-3 vh-100 ">
        <div className="bg-img"></div>
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <div className="border mt-3 p-4 fs-6 rounded-3 rounded-9 bg-white">
          <h5 className="mt-3 text-primary fw-bold text-center">ลงทะเบียนบริการ BAAC Connect</h5>
          <div className="text-center">กรุณากรอกข้อมูล</div>
          {/* <div className="mt-3 fs-14">เลขบัตรประชาชน</div> */}
          <div className={`input-group mt-2 ${idCard.length == 13 ? "mb-3" : ""}`}>
            <input inputMode="numeric" type="text" maxLength="13" className={` form-control rounded-5 ${idCard.length !== 13 && !firsttime ? "error" : ""}`} placeholder="เลขบัตรประชาชน" aria-label="idcard" onChange={this.setIdCard} value={idCard} />
          </div>
          {idCard.length !== 13 && !firsttime ? (
            <div className="fs-12 mb-3" style={{ color: "red" }}>
              เลขบัตรประชาชนต้องมี 13 หลัก
            </div>
          ) : (
            ""
          )}
          {/* <div className="form-check mt-2" onChange={this.setuseAMobile}>
            <input className="form-check-input" type="checkbox" value={checkUseAMobile} id="acc" />
            <label className="form-check-label" htmlFor="acc">
              สมัครใช้บริการโดยใช้เบอร์โทรศัพท์ที่สมัคร ธ.ก.ส. A-Mobile
            </label>
          </div> */}
          <div className="mt-3">
            <div>ลงทะเบียนบริการ BAAC Connect ฟรี</div>
            <ul className="mt-2">
              <li>แจ้งเตือนการใช้งานบัญชี</li>
              <li>รับสิทธิประโยชน์และข่าวสารต่าง ๆ</li>
              <li>พร้อมบริการอื่นๆ อีกมากมาย</li>
            </ul>
          </div>
          <div className="p-3 text-primary bg-green fs-14 mt-2 text-center">เพื่อความปลอดภัยในการใช้งานแอปพลิเคชัน LINE ธนาคารแนะนำให้ท่านตั้ง Passcode ในการใช้งาน โดยท่านสามารถตั้ง Passcode ได้ที่เมนู Setting -> Privacy -> Passcode lock</div>
        </div>

        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button type="button" onClick={() => this.setName("otp6")} disabled={idCard.length !== 13} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${idCard.length !== 13 ? "disable-btn" : ""}`}>
            ลงทะเบียน
          </button>
        </div>
      </div>
    );
  }
}

export default RegisAMobileMember;
