import React from "react";
import logo from "../../assets/logo.svg";

class Checkotp7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }
  setCode = (evt) => {
    let { data } = evt.nativeEvent;
    if (data) {
      if (this.state.code.length < 7) {
        let updateData = this.state.code + data.toString();
        this.setState({
          code: updateData,
        });
      }
    } else {
      let spliceData = this.state.code.substr(0, this.state.code.length - 1);
      this.setState({
        code: spliceData,
      });
    }
  };
  componentDidMount() {}
  render() {
    let { code } = this.state;
    return (
      <div className="Main-main mt-5 mx-2">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="mt-3 text-primary fw-bold ">รหัสยืนยัน 7 หลัก</h5>
        <div className="col-12 d-flex flex-column">
          <div className="d-flex flex-row justify-content-between mt-3" style={{ maxWidth: "350px" }}>
            <div>
              <input id="code=1" type="number" className="form-control rounded-5 text-center text-primary" style={{ width: "41px" }} placeholder="-" onChange={this.setCode} value={code[0] ? code[0] : ""} ref="1" />
            </div>
            <div>
              <input id="code=2" type="number" className="form-control rounded-5 text-center text-primary" style={{ width: "41px" }} placeholder="-" onChange={this.setCode} value={code[1] ? code[1] : ""} ref="2" />
            </div>
            <div>
              <input id="code=3" type="number" className="form-control rounded-5 text-center text-primary" style={{ width: "41px" }} placeholder="-" onChange={this.setCode} value={code[2] ? code[2] : ""} ref="3" />
            </div>
            <div>
              <input id="code=4" type="number" className="form-control rounded-5 text-center text-primary" style={{ width: "41px" }} placeholder="-" onChange={this.setCode} value={code[3] ? code[3] : ""} ref="4" />
            </div>
            <div>
              <input id="code=5" type="number" className="form-control rounded-5 text-center text-primary" style={{ width: "41px" }} placeholder="-" onChange={this.setCode} value={code[4] ? code[4] : ""} ref="5" />
            </div>
            <div>
              <input id="code=6" type="number" className="form-control rounded-5 text-center text-primary" style={{ width: "41px" }} placeholder="-" onChange={this.setCode} value={code[5] ? code[5] : ""} ref="6" />
            </div>
            <div>
              <input id="code=7" type="number" className="form-control rounded-5 text-center text-primary" style={{ width: "41px" }} placeholder="-" onChange={this.setCode} value={code[6] ? code[6] : ""} ref="7" />
            </div>
          </div>
          <div className="p-3 text-primary bg-green fs-14 mt-4 text-center">รหัสยืนยันได้จากการกด * 179 * เลขประจำตัวประชาชน # แล้วโทรออก</div>
          {/* <div className="mt-4 text-center">
            ไม่ได้รับรหัส? <span className="text-info text-decoration-underline">ส่งรหัส OTP อีกครั้ง</span>
          </div> */}
        </div>
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button type="button" onClick={() => this.props.back()} className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14">
            ยกเลิก
          </button>
          <button type="button" onClick={() => this.props.next()} disabled={code.length < 7} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${code.length < 7 ? "disable-btn" : ""}`}>
            ยืนยัน
          </button>
        </div>
      </div>
    );
  }
}

export default Checkotp7;
