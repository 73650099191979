import React from "react";
import aMobile from "../../assets/profile-menu-a-mobile.png";
import profilefooter from "../../assets/profilefooter.png";
import etc from "../../assets/etc.png";
import talk from "../../assets/talk.png";
import manual from "../../assets/manual.svg";
import lot from "../../assets/lot.png";
import cog from "../../assets/cog.png";
import check from "../../assets/check.png";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: {
        img: etc,
        name: "ภัทร์ศรัณย์ สวาทยานนท์",
        phoneno: "0989263955",
        loading: false,
      },
      menu: [
        {
          img: manual,
          name: "คู่มือใช้งาน",
        },
        // {
        //   img: talk,
        //   name: "คุยกับพี่แจ่มใส",
        // },
        // {
        //   img: aMobile,
        //   name: "A-Mobile",
        // },
        {
          img: check,
          name: "ตรวจผลสลาก",
        },
        {
          img: cog,
          name: "ตั้งค่าการใช้งาน BAAC Connect",
        },
        {
          img: lot,
          name: "สลากของฉัน",
        },
      ],
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };
  clickMenu = async (text) => {
    if (text === "คู่มือใช้งาน") {
      window.liff.openWindow({
        url: "https://drive.google.com/file/d/1kzq78_-ZqShrbK22UPPbwqAZnuG33PQU/view?usp=sharing",
        external: true,
      });
    } else if (text === "ตั้งค่าการใช้งาน BAAC Connect") {
      this.props.goTo("SettingMenu");
    } else if (text === "สลากของฉัน") {
      window.liff.openWindow({
        url: "https://liff.line.me/1654548648-ELVgrVV2/channels/5000179038/survey/form/01F9XEE6BVCK1P8BXKG5WZ2166",
        external: true,
      });
    }
    let message = text === "คุยกับพี่แจ่มใส" ? "menu" : "";
    if (message && !this.state.loading) {
      this.setState({
        loading: true,
      });
      try {
        await window.liff.sendMessages([
          {
            text: message,
            type: "text",
          },
        ]);
        this.setState({
          loading: false,
        });
        window.liff.closeWindow();
      } catch (error) {
        this.setState({
          loading: false,
        });
        alert(JSON.stringify(error));
        alert(JSON.stringify(error.message));
      }
    }
  };

  componentDidMount() {}
  render() {
    let { userData, menu } = this.state;
    return (
      <div className="Main-main w-100 mt-2  text-white">
        <div className="filter-black"></div>
        <div className="bg-img set-bg"></div>
        <div className="py-4 border-bottom broder-w-2">
          <div className="d-flex flex-row">
            <div style={{ width: 90, height: 90, overflow: "hidden", border: "2px solid #fff", minWidth: "90px" }} className="flex-all-center rounded-o">
              <img style={{ height: 90 }} src={userData.img} alt="logo" />
            </div>
            <div className="d-flex flex-column mx-3 justify-content-center">
              <h5>{userData.name}</h5>
              <div>โทรศัพท์ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {userData.phoneno}</div>
            </div>
          </div>
          <div className="mt-3 flex-all-center flex-column">
            <button
              onClick={() => {
                this.props.goTo("EditServiceMain");
              }}
              type="button"
              className={`btn text-white py-3 col-11 fs-14 boder-white`}
              style={{ borderRadius: "35px", backgroundColor: "rgba(255,255,255,.3)" }}
            >
              เปลี่ยนแปลงการแจ้งเตือน
            </button>
            {/* <button
              onClick={() => {
                this.props.goTo("CancelServiceMain");
              }}
              type="button"
              className={`btn text-white py-3 mt-3 col-11 fs-14 boder-white`}
              style={{ borderRadius: "35px", backgroundColor: "rgba(255,255,255,.3)" }}
            >
              ยกเลิกบริการ BAAC Connect
            </button> */}
          </div>
        </div>
        <div className="my-4">
          <h6>เมนู</h6>
          <div className="p-3 rounded-9 d-flex flex-row flex-wrap" style={{ backgroundColor: "rgba(255,255,255,.3)" }}>
            {menu.map((item) => {
              return (
                <div className="col-6 fs-12 flex-all-center flex-column mt-3" onClick={() => this.clickMenu(item.name)}>
                  <div style={{ height: 45, overflow: "hidden" }} className="flex-all-center ">
                    <img style={{ height: 45 }} src={item.img} alt="logo" />
                  </div>
                  <div className="mt-2 text-center">{item.name}</div>
                </div>
              );
            })}
          </div>
          <div className="mt-4 flex-all-center flex-column">
            <a className="w-100 flex-all-center" href="tel:025550555" style={{ textDecoration: "none" }}>
              <button type="button" className={`btn text-primary bg-white  col-11 fs-16 boder-white`} style={{ borderRadius: "35px" }}>
                ติดต่อธนาคาร
              </button>
            </a>
          </div>
          <div className="mt-5 flex-all-center flex-column">
            <div style={{ height: 90, overflow: "hidden" }} className="flex-all-center ">
              <img style={{ height: 90 }} src={profilefooter} alt="logo" />
            </div>
            <div className="fs-12">Version 1.0.1</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
