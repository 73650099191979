import React from "react";
import logo from "../../../assets/logo.svg";
import Radio from "@material-ui/core/Radio";

class Privacysetting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
      check2: null,
      check3: null,
      check4: null,
    };
  }

  clickAccept = (evt) => {
    let data = !this.state.accept;
    this.setState({
      accept: data,
    });
  };
  setName = (text) => {
    this.props.goTo(text);
  };
  updateCheck2 = (evt) => {
    let { value } = evt.target;
    // console.log(evt.target.value);
    this.setState({
      check2: value,
    });
  };
  updateCheck3 = (evt) => {
    let { value } = evt.target;
    this.setState({
      check3: value,
    });
  };
  updateCheck4 = (evt) => {
    let { value } = evt.target;
    this.setState({
      check4: value,
    });
  };

  componentDidMount() {}
  render() {
    let { accept, check2, check3, check4 } = this.state;
    return (
      <div className="Policy-main w-100 mt-5 p-3">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="mt-3 text-primary fw-bold">การตั้งค่าข้อกำหนดและเงื่อนไขการใช้บริการ BAAC Connect</h5>
        <div className="border mt-3  fs-6 rounded-8">
          <div className="p-4">
            <div className="my-4 font-weight-bold">1. การคุ้มครองข้อมูลส่วนบุคคล</div>
            <div>ธนาคารตระหนักถึงสิทธิส่วนบุคคลในข้อมูลของผู้ขอใช้บริการ เพื่อให้ข้อมูลของผู้ขอใช้บริการดำเนินธุรกรรมต่าง ๆ กับธนาคารจะดำเนินการเก็บรักษาข้อมูลนั้นอย่างเข้มงวดและป้องกันการนำข้อมูลนั้นไปใช้และ/หรือเปิดเผยข้อมูลที่แตกต่างไปจากวัตถุประสงค์และความยินยอมของผู้ขอใช้บริการ ตามเกณฑ์มาตรฐานสำหรับการรักษาข้อมูลที่เป็นความลับของธนาคาร ท่านสามารถเลือกให้หรือไม่ให้ควมยินยอมโดยไม่มีผลต่อการพิจารณาการใช้ผลิตภัณฑ์หรือบริการ ท่านสามารถดูรายละเอียดนโยบายการคุ้มครองข้อมูลส่วนบุคคลของธนาคารได้ที่เว็บไซต์ ...... ทั้งนี้ ท่านสามารถเปลี่ยนแปลงการให้ความยินยอมผ่าน ............ และ Call Center โทร. ............</div>
          </div>
        </div>
        <div className="border mt-3 fs-6 rounded-8">
          <div className="p-4">
            <div className="my-4 font-weight-bold">2. ความยิินยอมให้ธนาคารนำเสนอผลิตภัณฑ์หรือบริการ</div>
            <div>เพื่อให้ท่านได้รับข้อเสนอหรือสิทธิพิเศษเกี่ยวกับผลิตภัณฑ์ บริการ กิจกรรม โปรโมชั่น หรือข้อมูลข่าวสารต่าง ๆ ที่เป็นประโยชน์หรือเหมาะสมแก่ท่านยิ่งขึ้น ท่านยินยอมให้ธนาคารเก็บรวบรวม ใช้ หรือเปิดเผลข้อมูลของท่าน</div>
          </div>
          <div className="col-12 d-flex flex-row mt-5 border-top" onChange={this.updateCheck2}>
            <div className="col-6 p-3 flex-all-center border-right">
              <Radio className="p-0" checked={check2 === "ยินยอม"} value="ยินยอม" id="check2-1" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
              {/* <input className="form-check-input mr-2" type="radio" name="check2" id="check2-1" value="ยินยอม" /> */}
              <label className="font-weight-bold px-2" htmlFor="check2-1">
                ยินยอม
              </label>
            </div>
            <div className="col-6 p-3 flex-all-center">
              <Radio className="p-0" checked={check2 === "ไม่ยินยอม"} value="ไม่ยินยอม" id="check2-2" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
              {/* <input className="form-check-input mr-2" type="radio" name="check2" id="check2-2" value="ไม่ยินยอม" /> */}
              <label className="font-weight-bold px-2" htmlFor="check2-2">
                ไม่ยินยอม
              </label>
            </div>
          </div>
        </div>
        <div className="border mt-3 fs-6 rounded-8">
          <div className="p-4">
            <div className="my-4 font-weight-bold">3. ความยิินยอมให้ธนาคารพัฒนาผลิตภัณฑ์หรือบริการ</div>
            <div>เพื่อให้ท่านได้รับความพึงพอใจในผลิตภัณฑ์และบริการยิ่งขึ้นจากการประมวลผล การบริหารจัดการ การสอบทานตรวจสอบความถูกต้องของข้อมูล การวิเคราะห์ข้อมูล การปรับปรุงพัฒนาผลิตภัณฑ์ หรือบริการโดยธนาคาร เครือข่ายหรือพันธมิตรทางธุรกิจ ท่านยินยอมให้ธนาคารเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลของท่าน</div>
          </div>
          <div className="col-12 d-flex flex-row mt-5 border-top" onChange={this.updateCheck3}>
            <div className="col-6 p-3 flex-all-center border-right">
              <Radio className="p-0" checked={check3 === "ยินยอม"} value="ยินยอม" id="check3-1" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
              {/* <input className="form-check-input mr-2" type="radio" name="check3" id="check3-1" value="ยินยอม" /> */}
              <label className="font-weight-bold px-2" htmlFor="check3-1">
                ยินยอม
              </label>
            </div>
            <div className="col-6 p-3 flex-all-center">
              <Radio className="p-0" checked={check3 === "ไม่ยินยอม"} value="ไม่ยินยอม" id="check3-2" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
              {/* <input className="form-check-input mr-2" type="radio" name="check3" id="check3-2" value="ไม่ยินยอม" /> */}
              <label className="font-weight-bold px-2" htmlFor="check3-2">
                ไม่ยินยอม
              </label>
            </div>
          </div>
        </div>
        <div className="border mt-3 fs-6 rounded-8">
          <div className="p-4">
            <div className="my-4 font-weight-bold">4. ความยิินยอมให้เครือข่ายหรือพันธมิตรทางธุรกิจ นำเสนอผลิตภัณฑ์หรือบริการ</div>
            <div>เพื่อให้ท่านได้รับทางเลือกข้อเสนอผลิตภัณฑ์หรือบริการที่หลากหลาย รวมถึงโปรโมชั่นและสิทธิพิเศษต่าง ๆ ที่น่าสนใจหรือเป็นประโยชน์ของท่านยินยอมให้ธนาคารเปิดเผยข้อมูลของท่านให้แก่เครือข่ายและพันธมิตรทางธุรกิจดังกล่าว</div>
          </div>
          <div className="col-12 d-flex flex-row mt-5 border-top" onChange={this.updateCheck4}>
            <div className="col-6 p-3 flex-all-center border-right">
              <Radio className="p-0" checked={check4 === "ยินยอม"} value="ยินยอม" id="check4-1" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
              {/* <input className="form-check-input mr-2" type="radio" name="check4" id="check4-1" value="ยินยอม" /> */}

              <label className="font-weight-bold px-2" htmlFor="check4-1">
                ยินยอม
              </label>
            </div>
            <div className="col-6 p-3 flex-all-center">
              <Radio className="p-0" checked={check4 === "ไม่ยินยอม"} value="ไม่ยินยอม" id="check4-2" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
              {/* <input className="form-check-input mr-2" type="radio" name="check4" id="check4-2" value="ไม่ยินยอม" /> */}
              <label className="font-weight-bold px-2" htmlFor="check4-2">
                ไม่ยินยอม
              </label>
            </div>
          </div>
        </div>

        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button type="button" onClick={() => this.props.goto("Menu")} className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14">
            ยกเลิก
          </button>
          <button type="button" disabled={!check2 || !check3 || !check4} onClick={() => this.props.goto("Menu")} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${!check2 || !check3 || !check4 ? "disable-btn" : ""}`}>
            ยืนยัน
          </button>
        </div>
      </div>
    );
  }
}

export default Privacysetting;
