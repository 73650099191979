import React from "react";
import "./App.css";
import { Route } from "react-router-dom";
// import { Switch, NavLink } from "react-router-dom";
import Regis from "./components/regis/Regis";
import Profile from "./components/profile/Profile";
import "./assets/app.scss";
const liff = window.liff;
const Home = () => <h1>Home</h1>;
class App extends React.Component {
  async componentDidMount() {
    //main
    const liffid = "1656283053-yRWdNG8a";
    //test
    // const liffid = "1656283053-ZxbJ4dGr";
    var redirectUri = window.location.href;
    await liff.init({ liffId: `${liffid}` }).catch((err) => {
      throw err;
    });
    if (liff.isLoggedIn()) {
      let getProfile = await liff.getProfile();
      this.setState({
        name: getProfile.displayName,
        userLineID: getProfile.userId,
        pictureUrl: getProfile.pictureUrl,
      });
    } else {
      await liff.login({ redirectUri });
    }
  }
  render() {
    return (
      <div>
        <Route exact path="/" component={Profile} />
        <Route path="/regis" component={Regis} />
        <Route path="/profile" component={Profile} />
      </div>
    );
  }
}

export default App;
