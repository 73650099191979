import React from "react";
import logo from "../../assets/logo.svg";
import stepGray from "../../assets/step-gray.png";
import stepWhite from "../../assets/step-white.png";
import Saveing from "./selectNoti/Saveing";
import Credit from "./selectNoti/Credit";
import Etc from "./selectNoti/Etc";
import Overall from "./selectNoti/Overall";

class NotiForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoChoiceSize: 70,
      step: "saveing",
      saveingData: {},
      creditData: {},
      etcData: {},
    };
  }

  clickAutoAlert = (evt) => {
    let data = !this.state.autoAlert;
    this.setState({
      autoAlert: data,
    });
  };
  setName = (text) => {
    this.props.goTo(text);
  };
  back = () => {
    if (this.state.step === "etc") {
      this.setState({
        step: "credit",
      });
    } else if (this.state.step === "credit") {
      this.setState({
        step: "saveing",
      });
    } else if (this.state.step === "overall") {
      this.setState({
        step: "saveing",
      });
    } else {
      this.setState({
        step: "saveing",
      });
    }
  };
  setaccountcode = (item) => {
    this.setState({
      selectSaveing: item,
    });
  };
  saveDataSaveing = (item) => {
    this.setState({
      saveingData: item,
      // step: "credit",
      step: "overall",
    });
  };
  saveDatacredit = (item) => {
    this.setState({
      creditData: item,
      step: "etc",
    });
  };
  saveDataetc = (item) => {
    this.setState({
      etcData: item,
      step: "overall",
    });
  };

  componentDidMount() {}
  render() {
    let { step, saveingData, creditData, etcData } = this.state;
    return (
      <div className="NotiForm-main w-100 mt-5 p-3">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>

        {step === "overall" ? (
          <div>
            <Overall back={this.back} saveing={saveingData} credit={creditData} etc={etcData} />
          </div>
        ) : (
          <div>
            <h5 className="mt-3 text-primary fw-bold">กรุณาเลือกบัญชีที่ต้องการแจ้งเตือน</h5>
            {/* <div className="d-flex flex-column mt-4">
              <div className="d-flex flex-row align-items-center justify-content-center w-100">
                <div className="col-2 flex-all-center">
                  <div style={{ padding: "8px", width: "38px", height: "38px" }} className="flex-all-center bg-primary-light rounded-o w-max-content">
                    <img src={stepWhite} alt="logo" style={{ width: "18px" }} />
                  </div>
                </div>
                <div style={{ width: "17%" }} className={` border-bottom mx-0 broder-w-2 ${step === "credit" || step === "etc" ? "border-color-primary-light" : ""}`}></div>
                <div className="col-2 flex-all-center">
                  <div style={{ padding: "8px", width: "38px", height: "38px" }} className={`flex-all-center rounded-o w-max-content ${step === "credit" || step === "etc" ? "bg-primary-light" : "icon-step-none-select"}`}>
                    <img src={step === "credit" || step === "etc" ? stepWhite : stepGray} style={{ width: "18px" }} alt="logo" />
                  </div>
                </div>
                <div style={{ width: "17%" }} className={` border-bottom mx-0 broder-w-2 ${step === "etc" ? "border-color-primary-light" : ""}`}></div>
                <div className="col-2 flex-all-center">
                  <div style={{ padding: "8px", width: "38px", height: "38px" }} className={`flex-all-center rounded-o w-max-content ${step === "etc" ? "bg-primary-light" : "icon-step-none-select"}`}>
                    <img src={step === "etc" ? stepWhite : stepGray} style={{ width: "18px" }} alt="logo" />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row mt-1">
                <div className="col-4 fs-12 text-center text-primary-light">บริการเงินออมทรัพย​์</div>
                <div className={`col-4 fs-12 text-center  ${step === "credit" || step === "etc" ? "text-primary-light" : "text-gray-ex"}`}>บริการเงินสินเชื่อ</div>
                <div className={`col-4 fs-12 text-center  ${step === "etc" ? "text-primary-light" : "text-gray-ex"}`}>บริการอื่นๆ</div>
              </div>
            </div> */}
            {step === "saveing" ? <Saveing next={this.saveDataSaveing} data={saveingData} /> : step === "credit" ? <Credit next={this.saveDatacredit} back={this.back} data={creditData} /> : step === "etc" ? <Etc next={this.saveDataetc} back={this.back} data={etcData} /> : ""}
          </div>
        )}
      </div>
    );
  }
}

export default NotiForm;
