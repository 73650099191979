import React from "react";
import logo from "../../../assets/logo.svg";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setName = (text) => {
    this.props.goTo(text);
  };

  componentDidMount() {}
  render() {
    // let { accept, check2, check3, check4 } = this.state;
    let { back, goto, changeName } = this.props;
    return (
      <div className="Menu-main w-100 mt-5 p-3">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="mt-4 text-primary fw-bold">ตั้งค่าการใช้งาน</h5>
        <div className="mt-4">
          <h6 className="text-gold fs-20">การใช้งานส่วนตัว</h6>
          <div onClick={() => goto("EditData")} className="d-flex flex-row justify-content-between py-3 border-bottom">
            <span>ข้อมูลส่วนตัว</span>
            <span>&gt;</span>
          </div>
          <div onClick={() => goto("EditPhone")} className="d-flex flex-row justify-content-between py-3 border-bottom">
            <span>เบอร์โทรศัพท์มือถือ</span>
            <span>&gt;</span>
          </div>
        </div>
        <div className="mt-4">
          <h6 className="text-gold fs-20">ความเป็นส่วนตัว</h6>
          <div onClick={() => goto("Privacysetting")} className="d-flex flex-row justify-content-between py-3 border-bottom">
            <span>ตั้งค่าเงื่อนไขบริการ BAAC Connect</span>
            <span>&gt;</span>
          </div>
        </div>
        <div className="mt-4">
          <h6 className="text-gold fs-20">การบริการ</h6>
          <div onClick={() => changeName("CancelServiceMain")} className="d-flex flex-row justify-content-between py-3 border-bottom">
            <span>ยกเลิกบริการ BAAC Connect</span>
            <span>&gt;</span>
          </div>
        </div>
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button type="button" onClick={() => back()} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 `}>
            ย้อนกลับ
          </button>
        </div>
      </div>
    );
  }
}

export default Menu;
