import React from "react";
import etcimg from "../../../assets/etc.png";
import Radio from "@material-ui/core/Radio";
class Etc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoChoiceSize: 70,
      etcCredit: {},
      myEtcAccount: [
        {
          img: etcimg,
          name: "เงินสงเคราะห์ชีวิต",
        },
        {
          img: etcimg,
          name: "ผลิตภัณฑ์อื่นๆเพิ่มเติม",
        },
        {
          img: null,
          name: "ไม่ต้องการเลือกบัญชีแจ้งเตือน",
        },
      ],
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };
  setAccount = (item) => {
    this.setState({
      etcCredit: item,
    });
  };

  componentDidMount() {
    if (this.props.data && this.props.data.select && this.props.data.select.name) {
      this.setState({
        etcCredit: this.props.data.select,
      });
    }
  }
  render() {
    let { logoChoiceSize, etcCredit, myEtcAccount } = this.state;
    return (
      <div className="Etc-main w-100 mt-4">
        <div className="border mt-3 py-4 px-3 fs-6 rounded-9">
          {myEtcAccount.map((item, index) => {
            return (
              <div
                key={`etc-key-${index}`}
                className="d-flex flex-row my-2"
                onClick={() => {
                  this.setAccount(item);
                }}
              >
                <div className="px-2 d-flex align-items-center">
                  <Radio className="p-0" checked={etcCredit.name === item.name} name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
                  {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={etcCredit.name === item.name} /> */}
                </div>
                {item.img ? (
                  <div style={{ width: logoChoiceSize }} className="flex-all-center">
                    <img style={{ width: logoChoiceSize }} src={item.img} alt="logo" />
                  </div>
                ) : (
                  ""
                )}
                <div className={`d-flex m-3 flex-column justify-content-center ${!item.img ? "ml-0" : ""}`}>
                  <h6 className="mb-0">{item.name}</h6>
                </div>
              </div>
            );
          })}
        </div>
        {/* footer */}
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button onClick={() => this.props.back()} type="button" className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14 black-main">
            ย้อนกลับ
          </button>
          <button type="button" onClick={() => this.props.next({ select: etcCredit })} disabled={!etcCredit.accountcode && !etcCredit.name} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${!etcCredit.accountcode && !etcCredit.name ? "disable-btn" : ""}`}>
            ถัดไป
          </button>
        </div>
      </div>
    );
  }
}

export default Etc;
