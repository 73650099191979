import React from "react";
import logo from "../../assets/logo.svg";
import video from "../../assets/pre-video.mp4";
import poster from "../../assets/poster.png";

class Prepare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      docData: {
        new: ["1. เลขประจำตัวประชาชน", "2. วัน/เดือน/ปีเกิด (พ.ศ.) ที่ลงทะเบียนเครือข่ายมือถือเป็นชื่อตนเอง", "3. เบอร์โทรศัพท์มือถือ", "4. รหัสยืนยัน (7หลัก)"],
        aMobile: ["1. เลขประจำตัวประชาชน", "2. เบอร์โทรศัพท์มือถือที่สมัครแอพลิเคชั่นธกส. A-Mobile"],
      },
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };

  seemore = () => {
    window.liff.openWindow({
      url: "https://drive.google.com/file/d/1kzq78_-ZqShrbK22UPPbwqAZnuG33PQU/view?usp=sharing",
      external: true,
    });
  };

  componentDidMount() {}
  render() {
    let { docData, show } = this.state;
    return (
      <div className="prepare-main w-100 mt-5 p-3">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <video className="w-100 mt-4 rounded-9" poster={poster} controls>
          <source src={video} type="video/mp4" />
        </video>
        <h5 className="mt-3 text-primary fw-bold">เตรียมตัวก่อนลงทะเบียน</h5>
        <div className="mt-3">
          {/* <h6>สำหรับผู้ลงทะเบียนใหม่</h6> */}
          <h6>เตรียมข้อมูลดังนี้</h6>
          <div className="border mt-3 p-4 fs-6 rounded-6">
            {docData.new.map((item, index) => {
              return (
                <div key={`new-${index}`} className="fs-14">
                  {item}
                </div>
              );
            })}
            <div className="p-3 text-primary bg-green fs-14 mt-2 text-center">รหัสยืนยันได้จากการกด * 179 * เลขประจำตัวประชาชน # แล้วโทรออก</div>
          </div>
        </div>
        <div className="mt-3">
          <h6>
            สำหรับผู้ที่มีแอพลิเคชั่นธกส. A-Mobile <div>เตรียมข้อมูลดังนี้</div>
          </h6>
          <div className="border mt-3 p-4 fs-6 rounded-6">
            <div className="fs-14">1. เลขประจำตัวประชาชน</div>
            <div className="fs-14">
              2. เบอร์โทรศัพท์มือถือที่สมัครแอพลิเคชั่นธกส. <div>A-Mobile</div>
            </div>
          </div>
        </div>
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button onClick={() => this.seemore()} type="button" className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14">
            ศึกษาข้อมูลเพิ่มเติม
          </button>
          <button type="button" onClick={() => this.setName("policy")} className="btn btn-primary py-2 w-45 rounded-5 fs-14">
            เริ่มลงทะเบียน
          </button>
        </div>
      </div>
    );
  }
}

export default Prepare;
