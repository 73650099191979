import React from "react";
import accountgreen from "../../../assets/accountgreen.png";
import etcimg from "../../../assets/etc.png";
import book1 from "../../../assets/book-1.png";
import book2 from "../../../assets/book-2.png";
import book3 from "../../../assets/book-3.png";
import book4 from "../../../assets/book-4.png";
import creditimg from "../../../assets/credit.png";
import logo from "../../../assets/logo.svg";
import stepGray from "../../../assets/step-gray.png";

class SelectOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAccount: {},
      showBalance: true,
      logoChoiceSize: 50,
      activeAccount: {},
      mySaveingAccount: [
        {
          img: book1,
          name: "บัญชีออมทรัพย์ใช้สมุด",
          accountcode: "หมายเลข 310xxxx1437",
        },
      ],
      myCreditAccount: [
        {
          img: creditimg,
          name: "แจ้งผลบัญชีบัตรสินเชื่อ",
          accountcode: "หมายเลข 310xxxx1437",
        },
      ],
      myEtcAccount: [
        {
          img: etcimg,
          name: "เงินสงเคราะห์ชีวิต",
        },
      ],
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };
  setSelectAccount = (item) => {
    this.setState({
      selectAccount: item,
    });
  };
  clickshowBalance = (evt) => {
    let data = !this.state.showBalance;
    this.setState({
      showBalance: data,
    });
  };
  componentDidMount() {
    if (this.props.selectType === "บริการเงินออมทรัพย์") {
      this.setState({
        activeAccount: this.state.mySaveingAccount[0],
      });
    } else if (this.props.selectType === "บริการเงินสินเชื่อ") {
      this.setState({
        activeAccount: this.state.myCreditAccount[0],
      });
    } else if (this.props.selectType === "บริการอื่น ๆ") {
      this.setState({
        activeAccount: this.state.myEtcAccount[0],
      });
    }
  }
  render() {
    let { activeAccount } = this.state;
    let { selectType } = this.props;
    return (
      <div className="Main-main w-100 mt-5 ">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="mt-4 text-primary fw-bold text-center">กรุณายีนยันข้อมูลที่ต้องการยกเลิก</h5>
        <div className="mt-4">
          <div>
            <img src={stepGray} style={{ height: "20px" }} /> <span>{selectType}</span>
          </div>
        </div>
        <div>
          <div className=" mt-1 p-3 fs-6  bg-white d-flex flex-row flex-all-center">
            <div className="flex-all-center width-100-px">
              <img className="width-100-px" src={activeAccount.img} alt="logo" />
            </div>
            <div className="d-flex m-3 flex-column justify-content-center">
              <h6>{activeAccount.name}</h6>
              <div className="fs-14 fw-bold">{activeAccount.accountcode}</div>
            </div>
          </div>
        </div>

        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button onClick={() => this.props.back()} type="button" className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14 black-main">
            ยกเลิก
          </button>
          <button type="button" onClick={() => this.props.next("checkotp")} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 `}>
            ยืนยัน
          </button>
        </div>
      </div>
    );
  }
}

export default SelectOption;
