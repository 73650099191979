import React from "react";
import Main from "./Main";
import SettingMenu from "./SettingMenu";
import EditServiceMain from "./editService/EditServiceMain";
import CancelServiceMain from "./cancelService/CancelServiceMain";
import queryString from "query-string";
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        name: "Main",
      },
    };
  }

  updateQueryName = (text) => {
    this.props.history.push(`/profile?name=${text}`);
  };
  componentDidUpdate() {
    if (this.props.location.search && queryString.parse(this.props.location.search) && queryString.parse(this.props.location.search).name !== this.state.query.name) {
      this.setState({
        query: queryString.parse(this.props.location.search),
      });
    }
  }
  componentDidMount() {
    if (this.props.location.search && queryString.parse(this.props.location.search)) {
      this.setState({
        query: queryString.parse(this.props.location.search),
      });
    } else {
      this.props.history.push(`/profile?name=Main`);
    }
  }
  render() {
    let { name } = this.state.query;
    return <div className="p-3">{name === "Main" ? <Main goTo={this.updateQueryName} /> : name === "EditServiceMain" ? <EditServiceMain goTo={this.updateQueryName} /> : name === "CancelServiceMain" ? <CancelServiceMain goTo={this.updateQueryName} /> : name === "SettingMenu" ? <SettingMenu goTo={this.updateQueryName} /> : ""}</div>;
  }
}

export default Profile;
