import React from "react";
import Prepare from "./Prepare";
import Policy from "./Policy";
import RegisType from "./RegisType";
import NotiForm from "./NotiForm";
import RegisAMobileMember from "./RegisAMobileMember";
import RegisNewMember from "./RegisNewMember";
import Checkotp6 from "../otp/Checkotp6";
import Checkotp7 from "../otp/Checkotp7";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

class Regis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        name: "prepare",
      },
      registype: "",
    };
  }

  updateQueryName = (text) => {
    if (text === "RegisNewMember") {
      this.setState({
        registype: text,
      });
    } else if (text === "RegisAMobileMember") {
      this.setState({
        registype: text,
      });
    }
    this.props.history.push(`/regis?name=${text}`);
  };
  nextOtp6 = () => {
    this.updateQueryName("notiForm");
  };
  nextOtp7 = () => {
    this.updateQueryName("notiForm");
  };
  backOtp = () => {
    this.updateQueryName(this.state.registype);
  };
  backToPrepare = () => {
    this.props.history.push(`/regis?name=prepare`);
  };
  componentDidUpdate() {
    console.log(this.props.history.location.search);
    if (this.props.location.search && queryString.parse(this.props.location.search) && queryString.parse(this.props.location.search).name !== this.state.query.name) {
      this.setState({
        query: queryString.parse(this.props.location.search),
      });
    }
  }
  //   componentWillUnmount(nextProps, nextState) {
  //     console.log(nextProps, nextState);
  //   }
  componentDidMount() {
    // console.log();
    if (this.props.location.search && queryString.parse(this.props.location.search)) {
      this.setState({
        query: queryString.parse(this.props.location.search),
      });
    }
  }
  render() {
    let { name } = this.state.query;
    return <div>{name === "prepare" ? <Prepare goTo={this.updateQueryName} /> : name === "policy" ? <Policy goTo={this.updateQueryName} back={this.backToPrepare} /> : name === "registype" ? <RegisType goTo={this.updateQueryName} /> : name === "RegisNewMember" ? <RegisNewMember goTo={this.updateQueryName} /> : name === "RegisAMobileMember" ? <RegisAMobileMember goTo={this.updateQueryName} /> : name === "otp6" ? <Checkotp6 next={this.nextOtp6} back={this.backOtp} /> : name === "otp7" ? <Checkotp7 next={this.nextOtp7} back={this.backOtp} /> : name === "notiForm" ? <NotiForm goTo={this.updateQueryName} /> : ""}</div>;
  }
}

export default Regis;
