import React from "react";
import logo from "../../assets/logo.svg";

class Policy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: false,
    };
  }

  clickAccept = (evt) => {
    let data = !this.state.accept;
    this.setState({
      accept: data,
    });
  };
  setName = (text) => {
    this.props.goTo(text);
  };

  componentDidMount() {}
  render() {
    let { accept } = this.state;
    return (
      <div className="Policy-main w-100 mt-3 p-3">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="mt-3 text-primary fw-bold">
          ข้อกำหนดและเงื่อนไขการใช้บริการ <div>BAAC Connect</div>{" "}
        </h5>
        <div className="border mt-3 p-4 fs-6 rounded-3" style={{ height: "50vh", overflowY: "auto" }}>
          <div>BAAC Connect เป็นการให้บริการทางเครือข่ายอินเตอร์เน็ต ของธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร เพื่อ อำนวยความสะดวกให้แก่ผู้ขอใช้บริการของธนาคาร ในการใช้บริการต่างๆ เช่น การแจ้งยอดคงเหลือ หรือความ เคลื่อนไหวในบัญชีเงินฝาก และ/หรือธุรกรรม หรือบริการ ใดๆ ที่ธนาคารได้จัดให้มีขึ้นผ่านบริการ BAAC Connect ทั้งที่กำหนดไว้แล้วในขณะนี้ และ/หรือที่จะมีขึ้นต่อไปในภายหน้า โดยผู้ขอใช้บริการตกลงยินยอมผูกพันตามข้อกำหนดและเงื่อนไขการใช้บริการนี้ ดังต่อไปนี้</div>
          <div className="my-4">1. คำนิยาม</div>
          <div> 1.1 “ข้อมูลส่วนบุคคล” หมายความถึง ข้อมูลเกี่ยวกับ บุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่า ทางตรง หรือทางอ้อม เช่น ชื่อ–สกุล ที่อยู่ หมายเลขประจำตัว</div>
        </div>
        <div className="form-check mt-2" onChange={this.clickAccept}>
          <input className="form-check-input" type="checkbox" value={accept} id="acc" />
          <label className="form-check-label" htmlFor="acc">
            ยอมรับข้อตกลงเเละเงื่อนไข
          </label>
        </div>

        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button
            type="button"
            onClick={() => {
              this.props.back();
            }}
            className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14"
          >
            ยกเลิก
          </button>
          <button type="button" onClick={() => this.setName("registype")} disabled={!accept} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${!accept ? "disable-btn" : ""}`}>
            ยืนยัน
          </button>
        </div>
      </div>
    );
  }
}

export default Policy;
