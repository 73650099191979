import React from "react";
import logo from "../../../assets/logo.svg";

class EditPhone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneno: "",
      firsttime: {
        phoneno: true,
      },
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };

  setPhoneno = (evt) => {
    let { value } = evt.target;
    if (this.state.firsttime.phoneno) {
      let val = this.state.firsttime;
      val.phoneno = false;
      this.setState({
        firsttime: val,
      });
    }
    const re = /^[0-9\b]+$/;

    if (value.length <= 10 && (value === "" || re.test(value))) {
      this.setState({
        phoneno: value,
      });
    }
  };

  componentDidMount() {}
  render() {
    let { phoneno, firsttime } = this.state;
    let { goto } = this.props;
    return (
      <div className="Menu-main w-100 mt-5 p-3">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="my-4 text-primary fw-bold">เบอร์โทรศัพท์มือถือ</h5>
        <div className={`input-group ${phoneno.length == 10 ? "mb-3" : ""}`}>
          <input inputMode="numeric" type="text" maxLength="10" className={` form-control rounded-5 ${phoneno.length !== 10 && !firsttime.phoneno ? "error" : ""}`} placeholder="เบอร์โทรศัพท์มือถือ" aria-label="idcard" onChange={this.setPhoneno} value={phoneno} />
        </div>
        {phoneno.length !== 10 && !firsttime.phoneno ? (
          <div className="fs-12" style={{ color: "red" }}>
            เบอร์โทรศัพท์มือถือ 10 หลัก
          </div>
        ) : (
          ""
        )}
        <div className="p-3 text-primary bg-green fs-14 mt-4 text-center">คุณจะได้รับรหัสยืนยันตัวตน 7 หลัก เพื่อทำการยืนยันหมายเลขโทรศัพท์ของท่าน</div>
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button type="button" onClick={() => goto("Menu")} className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14">
            ยกเลิก
          </button>
          <button disabled={phoneno.length !== 10} type="button" onClick={() => goto("Otp")} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${phoneno.length !== 10 ? "disable-btn" : ""}`}>
            ถัดไป
          </button>
        </div>
      </div>
    );
  }
}

export default EditPhone;
