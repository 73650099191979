import React from "react";
import accountgreen from "../../../assets/accountgreen.png";
import etcimg from "../../../assets/etc.png";
import book1 from "../../../assets/book-1.png";
import book2 from "../../../assets/book-2.png";
import book3 from "../../../assets/book-3.png";
import book4 from "../../../assets/book-4.png";
import creditimg from "../../../assets/credit.png";
import logo from "../../../assets/logo.svg";
import Radio from "@material-ui/core/Radio";

class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAccount: {},
      showBalance: true,
      autoAlert: true,
      logoChoiceSize: 50,
      activeAccount: {},
      accountOption: [],
      mySaveingAccount: [
        {
          img: book1,
          name: "บัญชีออมทรัพย์ใช้สมุด",
          accountcode: "หมายเลข 310xxxx1437",
        },
        {
          img: book2,
          name: "บัญชีออมทรัพย์ทวีโชค",
          accountcode: "หมายเลข 900xxxx8242",
        },
        {
          img: book3,
          name: "บัญชีออมทรัพย์รักษาทรัพย์",
          accountcode: "หมายเลข 900xxxx8243",
        },
        {
          img: book4,
          name: "ออมทรัพย์ A-Saving",
          accountcode: "หมายเลข 900xxxx8244",
        },
      ],
      myCreditAccount: [
        {
          img: creditimg,
          name: "แจ้งผลบัญชีบัตรสินเชื่อ",
          accountcode: "หมายเลข 310xxxx1437",
        },
        {
          img: creditimg,
          name: "แจ้งผลบัญชีบัตรสินเชื่อ",
          accountcode: "หมายเลข 310xxxx1438",
        },
      ],
      myEtcAccount: [
        {
          img: etcimg,
          name: "เงินสงเคราะห์ชีวิต",
          accountcode: "test2",
        },
        {
          img: etcimg,
          name: "ผลิตภัณฑ์อื่นๆเพิ่มเติม",
          accountcode: "test1",
        },
      ],
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };
  setSelectAccount = (item) => {
    this.setState({
      selectAccount: item,
    });
  };
  clickshowBalance = (evt) => {
    let data = !this.state.showBalance;
    this.setState({
      showBalance: data,
    });
  };
  clickautoAlert = (evt) => {
    let data = !this.state.autoAlert;
    this.setState({
      autoAlert: data,
    });
  };
  pushData = () => {
    if (this.props.selectType === "บริการเงินออมทรัพย์") {
      this.props.next({ select: this.state.selectAccount, showBalance: this.state.showBalance, autoAlert: this.state.autoAlert });
    } else {
      this.props.next({ select: this.state.selectAccount });
    }
  };
  componentDidMount() {
    if (this.props.selectType === "บริการเงินออมทรัพย์") {
      this.setState({
        accountOption: this.state.mySaveingAccount,
        activeAccount: this.state.mySaveingAccount[1],
      });
    } else if (this.props.selectType === "บริการเงินสินเชื่อ") {
      this.setState({
        accountOption: this.state.myCreditAccount,
        activeAccount: this.state.myCreditAccount[1],
      });
    } else if (this.props.selectType === "บริการอื่น ๆ") {
      this.setState({
        accountOption: this.state.myEtcAccount,
        activeAccount: this.state.myEtcAccount[1],
      });
    }
  }
  render() {
    let { activeAccount, accountOption, selectAccount, showBalance, autoAlert } = this.state;
    let { selectType } = this.props;
    return (
      <div className="Main-main w-100 mt-5 ">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="mt-3 text-primary fw-bold ">เปลี่ยนหมายเลขบัญชีที่ต้องการให้แจ้งเตือน</h5>
        <div className="text-center">ข้อมูลปัจจุบัน</div>
        <div>
          <div className=" mt-3 p-3 fs-6  bg-white d-flex flex-row flex-all-center">
            <div className="flex-all-center width-100-px">
              <img className="width-100-px" src={activeAccount.img} alt="logo" />
            </div>
            <div className="d-flex m-3 flex-column justify-content-center">
              <h6>{activeAccount.name}</h6>
              <div className="fs-14 fw-bold">{activeAccount.accountcode}</div>
            </div>
          </div>
        </div>
        <div className="text-center">แก้เป็น</div>
        <div className="border mt-3 p-3 fs-6 rounded-9 bg-white d-flex flex-column">
          {accountOption.map((item, index) => {
            return (
              <div
                key={`saveing-key-${index}`}
                className="d-flex flex-row mt-2"
                onClick={() => {
                  this.setSelectAccount(item);
                }}
              >
                <div className="px-2 d-flex align-items-center">
                  <Radio className="p-0" checked={selectAccount.accountcode === item.accountcode} id="Radios1" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
                  {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectAccount.accountcode === item.accountcode} /> */}
                </div>
                <div className="flex-all-center width-85-px">
                  <img className="width-85-px" src={item.img} alt="logo" />
                </div>
                <div className="d-flex m-3 flex-column justify-content-center">
                  <h6 className="mb-0">{item.name}</h6>
                  {selectType !== "บริการอื่น ๆ" ? <div className="fw-bold fs-14">{item.accountcode}</div> : ""}
                </div>
              </div>
            );
          })}
        </div>
        {selectType === "บริการเงินออมทรัพย์" ? (
          <div className="mt-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value={showBalance} id="showBalance" onChange={this.clickshowBalance} defaultChecked />
              <label className="form-check-label" htmlFor="showBalance">
                แสดงยอดเงินคงเหลือ
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value={showBalance} id="autoAlert" onChange={this.clickautoAlert} defaultChecked />
              <label className="form-check-label" htmlFor="autoAlert">
                แจ้งผลสลากอัตโนมัติ และสลากที่จะครบกำหนด
              </label>
              <label className="form-check-label text-gray-ex fs-11" htmlFor="autoAlert">
                (เมื่อถูกรางวัลสลากออมทรัพย์/ เมื่อสลากออมทรัพย์ใกล้หมดอายุ/ เมื่อฝากสลากออมทรัพย์)
              </label>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button onClick={() => this.props.back()} type="button" className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14 black-main">
            ยกเลิก
          </button>
          <button type="button" onClick={() => this.pushData()} disabled={!selectAccount.accountcode && !selectAccount.name} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${!selectAccount.accountcode && !selectAccount.name ? "disable-btn" : ""}`}>
            ถัดไป
          </button>
        </div>
      </div>
    );
  }
}

export default Edit;
