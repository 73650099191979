import React from "react";
import accountgreen from "../../../assets/accountgreen.png";
import logo from "../../../assets/logo.svg";
import SelectOption from "./SelectOption";
import ShowActiveAccount from "./ShowActiveAccount";
import Checkotp7 from "../../otp/Checkotp7";

class CancelServiceMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: "select",
      selectType: {},
      loading: false,
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };
  setSelectType = (item) => {
    this.setState({
      selectType: item,
    });
  };
  setstepWithOption = (item, setectData) => {
    this.setState({
      step: item,
      selectType: setectData,
    });
  };
  setstep = (item) => {
    this.setState({
      step: item,
    });
  };
  back = () => {
    this.setState({
      step: "select",
    });
  };
  submit = async () => {
    if ((await window.liff.isInClient()) && !this.state.loading) {
      this.setState({
        loading: true,
      });
      const message = "ยกเลิกบริการ BAAC Connect เรียบร้อย";
      // const message = "ท่านได้ทำการยกเลิกบริการ BAAC Connect เรียบร้อยแล้ว";
      // const temp_img ="https://zwizai.s3.amazonaws.com/ecommerce/1624350856886Productiteration-amico.png"
      try {
        await window.liff.sendMessages([
          {
            text: message,
            type: "text",
          },
          // { originalContentUrl: `https://${window.location.hostname}/cancel-done.png`, previewImageUrl: `https://${window.location.hostname}/cancel-done.png`, type: "image" },
        ]);
        this.setState({
          loading: false,
        });
        window.liff.closeWindow();
      } catch (error) {
        this.setState({
          loading: false,
        });
        alert(JSON.stringify(error));
        alert(JSON.stringify(error.message));
      }
    }
  };

  componentDidMount() {}
  render() {
    let { step, selectType } = this.state;
    return <div className="EditServiceMain-main w-100 mt-5 ">{step === "select" ? <SelectOption next={this.setstepWithOption} /> : step === "showActiveAccount" ? <ShowActiveAccount selectType={selectType.name} next={this.setstep} back={this.back} /> : step === "checkotp" ? <Checkotp7 next={this.submit} back={this.back} /> : ""}</div>;
  }
}

export default CancelServiceMain;
