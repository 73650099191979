import React from "react";
import logo from "../../../assets/logo.svg";

class EditData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "ภัทร์ศรัณย์",
      lastName: "สวาทยานนท์",
      firsttime: {
        firstName: true,
        lastName: true,
      },
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };

  setFirstName = (evt) => {
    let { value } = evt.target;
    if (this.state.firsttime.firstName) {
      let val = this.state.firsttime;
      val.firstName = false;
      this.setState({
        firsttime: val,
      });
    }

    this.setState({
      firstName: value,
    });
  };
  setLastName = (evt) => {
    let { value } = evt.target;
    if (this.state.firsttime.lastName) {
      let val = this.state.firsttime;
      val.lastName = false;
      this.setState({
        firsttime: val,
      });
    }

    this.setState({
      lastName: value,
    });
  };

  componentDidMount() {}
  render() {
    let { firstName, lastName } = this.state;
    let { goto } = this.props;
    return (
      <div className="Menu-main w-100 mt-5 p-3">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="my-4 text-primary fw-bold">ข้อมูลส่วนตัว</h5>

        <div>
          <div className="text-gray-ex">ชื่อ</div>
          <input type="text" className={` form-control border-less-input`} placeholder="ชื่อ" onChange={this.setFirstName} value={firstName} />
        </div>
        <div>
          <div className="text-gray-ex">นามสกุล</div>
          <input type="text" className={` form-control border-less-input`} placeholder="ชื่อ" onChange={this.setLastName} value={lastName} />
        </div>

        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button type="button" onClick={() => this.props.goto("Menu")} className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14">
            ยกเลิก
          </button>
          <button disabled={!firstName || !lastName} type="button" onClick={() => this.props.goto("Otp")} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${!firstName || !lastName ? "disable-btn" : ""}`}>
            ถัดไป
          </button>
        </div>
      </div>
    );
  }
}

export default EditData;
