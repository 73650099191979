import React from "react";
import creditimg from "../../../assets/credit.png";
import Radio from "@material-ui/core/Radio";
class Credit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoChoiceSize: 70,
      selectCredit: {},
      myCreditAccount: [
        {
          img: creditimg,
          name: "แจ้งผลบัญชีบัตรสินเชื่อ",
          accountcode: "หมายเลข 310xxxx1437",
        },
        {
          img: creditimg,
          name: "แจ้งผลบัญชีบัตรสินเชื่อ",
          accountcode: "หมายเลข 310xxxx1438",
        },
        {
          img: null,
          name: "ไม่ต้องการเลือกบัญชีแจ้งเตือน",
          accountcode: null,
        },
      ],
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };
  setaccountcode = (item) => {
    this.setState({
      selectCredit: item,
    });
  };

  componentDidMount() {
    if (this.props.data && this.props.data.select && this.props.data.select.name) {
      this.setState({
        selectCredit: this.props.data.select,
      });
    }
  }
  render() {
    let { logoChoiceSize, selectCredit, myCreditAccount } = this.state;
    return (
      <div className="Credit-main w-100 mt-4">
        <div className="border mt-3 py-4 px-3 fs-6 rounded-9">
          {myCreditAccount.map((item, index) => {
            return (
              <div
                key={`credit-key-${index}`}
                className="d-flex flex-row"
                onClick={() => {
                  this.setaccountcode(item);
                }}
              >
                <div className="px-2 d-flex align-items-center">
                  <Radio className="p-0" checked={selectCredit.accountcode === item.accountcode} name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
                  {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectCredit.accountcode === item.accountcode} /> */}
                </div>
                {item.img ? (
                  <div style={{ width: logoChoiceSize }} className="flex-all-center">
                    <img style={{ width: logoChoiceSize }} src={item.img} alt="logo" />
                  </div>
                ) : (
                  ""
                )}
                <div className={`d-flex m-3 flex-column justify-content-center ${!item.img ? "ml-0" : ""}`}>
                  <h6 className="mb-0">{item.name}</h6>
                  <div className="fw-bold fs-14 mt-1">{item.accountcode}</div>
                </div>
              </div>
            );
          })}
        </div>
        {/* footer */}
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button onClick={() => this.props.back()} type="button" className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14 black-main">
            ย้อนกลับ
          </button>
          <button type="button" onClick={() => this.props.next({ select: selectCredit })} disabled={!selectCredit.accountcode && !selectCredit.name} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${!selectCredit.accountcode && !selectCredit.name ? "disable-btn" : ""}`}>
            ถัดไป
          </button>
        </div>
      </div>
    );
  }
}

export default Credit;
