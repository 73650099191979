import React from "react";
import logo from "../../assets/logo.svg";
import imgnew from "../../assets/new.svg";
import imgamobile from "../../assets/amobile.svg";
import Radio from "@material-ui/core/Radio";

class RegisType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      check: "",
      logoChoiceSize: 50,
    };
  }

  setCheck = (text) => {
    this.setState({
      check: text,
    });
  };

  gotoRegisFrom = () => {
    let path = this.state.check === "amobile" ? "RegisAMobileMember" : "RegisNewMember";
    // console.log(this.state.check, path);
    this.props.goTo(path);
  };

  componentDidMount() {}
  render() {
    let { check, logoChoiceSize } = this.state;
    return (
      <div className="RegisType-main w-100 pt-5 bg-warning  p-3 vh-100">
        <div className="bg-img"></div>
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <div className="flex-all-center" style={{ height: "60vh" }}>
          <div className="border px-2 py-3 fs-6 col-11 h-max-content boder-white rounded-9 bg-white">
            <h5 className="mt-3 text-primary fw-bold text-center">กรุณาเลือกวิธีสมัคร</h5>
            <h5 className="mt-2 text-primary fw-bold text-center">BAAC Connect</h5>
            <div
              className="d-flex flex-row mt-5"
              onClick={() => {
                this.setCheck("new");
              }}
            >
              <div className="d-flex align-items-center px-2">
                <Radio className="p-0" checked={check === "new"} value="new" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
                {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={check === "new"} /> */}
              </div>
              <div style={{ width: logoChoiceSize }} className="flex-all-center">
                <img style={{ height: logoChoiceSize }} src={imgnew} alt="logo" />
              </div>
              <div className="d-flex mx-3 my-2 flex-column justify-content-center">
                <h6 className="fw-bold text-primary">ไม่มี ธ.ก.ส. A-Mobile</h6>
                <div className="fs-12">สมัครด้วยบัตรประชาชน และเบอร์โทรศัพท์มือถือ</div>
              </div>
            </div>
            <div
              className="d-flex flex-row"
              onClick={() => {
                this.setCheck("amobile");
              }}
            >
              <div className="d-flex align-items-center px-2">
                <Radio className="p-0" checked={check === "amobile"} value="new" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
                {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={check === "amobile"} /> */}
              </div>
              <div style={{ width: logoChoiceSize }} className="flex-all-center">
                <img style={{ height: logoChoiceSize }} src={imgamobile} alt="logo" />
              </div>
              <div className="d-flex mx-3 my-2 flex-column justify-content-center">
                <h6 className="fw-bold text-primary">มี ธ.ก.ส. A-Mobile</h6>
                <div className="fs-12">หมายเลขเบอร์โทรศัพท์มือถือที่ลงทะเบียน A-Mobile</div>
              </div>
            </div>
            <div className="flex-all-center mt-4">
              <button type="button" onClick={() => this.gotoRegisFrom()} disabled={!check} className={`btn btn-primary col-6 rounded-5 py-2 fs-14 ${!check ? "disable-btn" : ""}`}>
                ลงทะเบียน
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegisType;
