import React from "react";
import book1 from "../../../assets/book-1.png";
import book2 from "../../../assets/book-2.png";
import book3 from "../../../assets/book-3.png";
import book4 from "../../../assets/book-4.png";
import Radio from "@material-ui/core/Radio";
import al from "../../../assets/al.svg";
import Modal from "react-bootstrap/Modal";

class Saveing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      autoAlert: false,
      showSaveing: false,
      logoChoiceSize: 70,
      selectSaveing: {},
      mySaveingAccount: [
        {
          img: book1,
          name: "บัญชีออมทรัพย์ใช้สมุด",
          accountcode: "หมายเลข 310xxxx1437",
        },
        {
          img: book2,
          name: "บัญชีออมทรัพย์ทวีโชค",
          accountcode: "หมายเลข 900xxxx8242",
        },
        {
          img: book3,
          name: "บัญชีออมทรัพย์รักษาทรัพย์",
          accountcode: "หมายเลข 900xxxx8243",
        },
        {
          img: book4,
          name: "ออมทรัพย์ A-Saving",
          accountcode: "หมายเลข 900xxxx8244",
        },
      ],
    };
  }

  handleClose = () => {
    this.setState({
      show: false,
    });
  };

  clickAutoAlert = (evt) => {
    let data = !this.state.autoAlert;
    this.setState({
      autoAlert: data,
    });
  };
  clickShowSaveing = (evt) => {
    console.log(evt);
    let data = !this.state.showSaveing;
    this.setState({
      showSaveing: data,
    });
  };
  setName = (text) => {
    this.props.goTo(text);
  };
  setaccountcode = (item) => {
    this.setState({
      selectSaveing: item,
    });
  };

  componentDidMount() {
    if (this.props.data && this.props.data.select && this.props.data.select.name) {
      this.setState({
        selectSaveing: this.props.data.select,
        autoAlert: this.props.data.autoAlert,
        showSaveing: this.props.data.showSaveing,
      });
    }
  }
  render() {
    let { logoChoiceSize, selectSaveing, autoAlert, mySaveingAccount, showSaveing, show } = this.state;
    return (
      <div className="Saveing-main w-100 mt-4">
        <div className="border mt-3 py-4 px-3 fs-6 rounded-9">
          {mySaveingAccount.map((item, index) => {
            return (
              <div
                key={`saveing-key-${index}`}
                className="d-flex flex-row"
                onClick={() => {
                  this.setaccountcode(item);
                }}
              >
                <div className="d-flex align-items-center px-2">
                  <Radio className="p-0" checked={selectSaveing.accountcode === item.accountcode} value="new" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
                  {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectSaveing.accountcode === item.accountcode} /> */}
                </div>
                <div style={{ width: logoChoiceSize }} className="flex-all-center">
                  <img style={{ width: logoChoiceSize }} src={item.img} alt="logo" />
                </div>
                <div className="d-flex m-3 flex-column justify-content-center">
                  <h6 className="mb-0">{item.name}</h6>
                  <div className="fw-bold fs-14">{item.accountcode}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mt-3">
          <div className="form-check" onChange={this.clickShowSaveing}>
            <input className="form-check-input" type="checkbox" value={true} id="showSaveing" defaultChecked={this.props.data.showSaveing} />
            <label className="form-check-label" htmlFor="showSaveing">
              แสดงยอดเงินคงเหลือ
            </label>
          </div>
          <div className="form-check" onChange={this.clickAutoAlert}>
            <input className="form-check-input" type="checkbox" value={true} id="autoAlert" defaultChecked={this.props.data.autoAlert} />
            <label className="form-check-label" htmlFor="autoAlert">
              รับการแจ้งเตือนเกี่ยวกับสลากออมทรัพย์
            </label>
            <label className="form-check-label text-gray-ex fs-11" htmlFor="autoAlert">
              (เมื่อถูกรางวัลสลากออมทรัพย์/ เมื่อสลากออมทรัพย์ใกล้หมดอายุ/ เมื่อฝากสลากออมทรัพย์)
            </label>
          </div>
        </div>
        {/* footer */}
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button type="button" onClick={() => this.props.next({ showSaveing, autoAlert, select: selectSaveing })} disabled={!selectSaveing.accountcode} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${!selectSaveing.accountcode ? "disable-btn" : ""}`}>
            ถัดไป
          </button>
        </div>
        <Modal show={show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>
            <h5 className="text-center my-3 text-primary">คำเตือน</h5>
            <div className="d-flex flex-row">
              <div className="col-4 d-flex flex-column align-items-center">
                <img className="mt-2 col-7" src={al} alt="al" />
              </div>
              <div className="col-8">
                <div className="fs-16">ระบบพบว่า ท่านเคยสมัครบริการ BAAC Connect ไว้แล้ว ท่านยืนยันสมัครบริการใหม่โดยใช้ LINE ID ใหม่จากโทรศัพท์มือถือเครื่องนี้ใช่หรือไม่</div>
                <div className="mt-2">การสมัครใหม่จะทำให้ BAAC Connect ในโทรศัพท์มือถือก่อนหน้านี้ไม่สามารถใช้บริการได้อีกต่อไป</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className=" d-flex flex-row justify-content-around">
            <button onClick={this.handleClose} type="button" className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14">
              ยกเลิก
            </button>
            <button type="button" onClick={this.handleClose} className="btn btn-primary py-2 w-45 rounded-5 fs-14">
              สมัครใหม่
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Saveing;
