import React from "react";
import Menu from "./SettingMenu/Menu.js";
import Privacysetting from "./SettingMenu/Privacysetting";
import EditPhone from "./SettingMenu/EditPhone";
import EditData from "./SettingMenu/EditData";
import Checkotp7 from "../otp/Checkotp7";
// import Checkotp6 from "./otp/Checkotp6";

class SettingMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: "Menu",
    };
  }

  goto = (text) => {
    this.setState({
      step: text,
    });
  };

  nextOtp = () => {
    console.log("next");
  };

  backOtp = () => {
    this.props.goTo("Menu");
  };

  back = () => {
    this.props.goTo("Main");
  };

  submit = async () => {
    console.log("Submit");
  };

  changeName = async (text) => {
    this.props.history.push(`/profile?name=${text}`);
  };

  componentDidMount() {}
  render() {
    let { step } = this.state;
    let changeName = this.props.goTo;
    return <div>{step === "Menu" ? <Menu back={this.back} goto={this.goto} changeName={changeName} /> : step === "Privacysetting" ? <Privacysetting goto={this.goto} /> : step === "EditPhone" ? <EditPhone goto={this.goto} /> : step === "EditData" ? <EditData goto={this.goto} /> : step === "Otp" ? <Checkotp7 next={this.nextOtp} back={this.backOtp} /> : ""}</div>;
  }
}

export default SettingMenu;
