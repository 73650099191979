import React from "react";
import etcimg from "../../../assets/etc.png";
import book1 from "../../../assets/book-1.png";
import book2 from "../../../assets/book-2.png";
import book3 from "../../../assets/book-3.png";
import book4 from "../../../assets/book-4.png";
import creditimg from "../../../assets/credit.png";
import stepGray from "../../../assets/step-gray.png";
import logo from "../../../assets/logo.svg";
import Radio from "@material-ui/core/Radio";
class Overall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logoChoiceSize: 70,
      // showBalance: null,
      loading: false,
    };
    // this.onChangeValue = this.onChangeValue.bind(this);
  }

  setName = (text) => {
    this.props.goTo(text);
  };

  componentDidMount() {}
  render() {
    let { logoChoiceSize, saveing, credit, etc } = this.props;
    let { showBalance } = this.state;
    return (
      <div className="Overall-main mt-5 mx-2">
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <h5 className="mt-4 mb-3 text-primary fw-bold text-center">กรุณายืนยันข้อมูลการแก้ไข BAAC Connect</h5>
        {/* first item */}
        <div id="card-content">
          <div id="saveing">
            <div>
              <img className="mr-2" src={stepGray} style={{ width: "24px" }} /> บริการออมทรัพย์
            </div>
            <div className="border mt-3 p-3 fs-6 rounded-9 bg-white d-flex flex-row">
              <div style={{ width: logoChoiceSize }} className="flex-all-center">
                <img style={{ width: logoChoiceSize }} src={saveing.select.img} alt="logo" />
              </div>
              <div className="d-flex m-3 flex-column justify-content-center">
                <h6>{saveing.select.name}</h6>
                <div className="fs-14 fw-bold">{saveing.select.accountcode}</div>
              </div>
            </div>
            <div className="mt-2">
              <div className="form-check">
                <input disabled className="form-check-input bg-gray-ex" type="checkbox" checked={saveing.showSaveing} id="showSaveing" />
                <label className="form-check-label" htmlFor="showSaveing">
                  แสดงยอดเงินคงเหลือ
                </label>
              </div>
              <div className="form-check">
                <input disabled className="form-check-input bg-gray-ex" type="checkbox" checked={saveing.autoAlert} id="autoAlert" />
                <label className="form-check-label" htmlFor="autoAlert">
                  แจ้งผลสลากอัตโนมัติ และสลากที่จะครบกำหนด
                </label>
                <label className="form-check-label text-gray-ex fs-11" htmlFor="autoAlert">
                  (เมื่อถูกรางวัลสลากออมทรัพย์/ เมื่อสลากออมทรัพย์ใกล้หมดอายุ/ เมื่อฝากสลากออมทรัพย์)
                </label>
              </div>
            </div>
          </div>
          {credit && credit.select && credit.select.name !== "ไม่ต้องการเลือกบัญชีแจ้งเตือน" ? (
            <div id="credit" className="mt-3">
              <div>
                <img className="mr-2" src={stepGray} style={{ width: "24px" }} /> บริการเงินสินเชื่อ
              </div>
              <div className="border mt-3 p-3 fs-6 rounded-9 bg-white d-flex flex-row">
                <div style={{ width: logoChoiceSize }} className="flex-all-center">
                  <img style={{ width: logoChoiceSize }} src={credit.select.img} alt="logo" />
                </div>
                <div className="d-flex m-3 flex-column justify-content-center">
                  <h6>{credit.select.name}</h6>
                  <div className="fs-14 fw-bold">{credit.select.accountcode}</div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {etc && etc.select && etc.select.name !== "ไม่ต้องการเลือกบัญชีแจ้งเตือน" ? (
            <div id="etc" className="mt-3">
              <div>
                <img className="mr-2" src={stepGray} style={{ width: "24px" }} /> บริการเงินอื่น ๆ
              </div>
              <div className="border mt-3 p-3 fs-6 rounded-9 bg-white d-flex flex-row">
                <div className="width-85-px" className="flex-all-center">
                  <img className="width-85-px" src={etc.select.img} alt="logo" />
                </div>
                <div className="d-flex m-3 flex-column justify-content-center">
                  <h6>{etc.select.name}</h6>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div className="mt-3" onChange={this.onChangeValue}>
              <div>คุณต้องการแสดงยอดเงินคงเหลือในบัญชีหรือไม่?</div>
              <div className="px-2  mt-3" style={{ display: "flex", justifyContent: "stretch" }}>
                <Radio className="p-0" checked={showBalance == 1} id="Radios1" value="1" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
                <label className="form-check-label" style={{ marginLeft: "5px " }} htmlFor="Radios1">
                  ต้องการแสดงยอดเงินคงเหลือ
                </label>
              </div>
              <div className="px-2 mt-2" style={{ display: "flex", justifyContent: "stretch" }}>
                <Radio className="p-0" checked={showBalance == 2} id="Radios2" value="2" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" />
                <label className="form-check-label" style={{ marginLeft: "5px " }} htmlFor="Radios2">
                  ไม่ต้องการแสดงยอดเงินคงเหลือ
                </label>
              </div>
            </div> */}
        </div>
        {/* footer */}
        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button
            type="button"
            onClick={() => {
              this.props.back();
            }}
            className="btn btn-outline-secondary bg-gray1 border py-2 w-45 rounded-5 fs-14"
          >
            ยกเลิก
          </button>
          <button type="button" onClick={() => this.props.next()} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 `}>
            ยืนยัน
          </button>
        </div>
      </div>
      // ${showBalance === null ? "disable-btn" : ""}
    );
  }
}

export default Overall;
