import React from "react";
import accountgreen from "../../../assets/accountgreen.png";
import logo from "../../../assets/logo.svg";
import Radio from "@material-ui/core/Radio";

class SelectOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: "select",
      selectType: {
        img: accountgreen,
        name: "บริการเงินออมทรัพย์",
        detail: "บัญชีออมทรัพย์ ออมทรัพย์ทวีโชค ออมทรัพย์รักษาทรัพย์ สลากออมทรัพย์ ธกส.",
      },
      logoChoiceSize: 50,
      option: [
        {
          img: accountgreen,
          name: "บริการเงินออมทรัพย์",
          detail: "บัญชีออมทรัพย์ ออมทรัพย์ทวีโชค ออมทรัพย์รักษาทรัพย์ สลากออมทรัพย์ ธกส.",
        },
        // {
        //   img: accountgreen,
        //   name: "บริการเงินสินเชื่อ",
        //   detail: "บัตรสินเชื่อเกษตรสุขใจ",
        // },
        // {
        //   img: accountgreen,
        //   name: "บริการอื่น ๆ",
        //   detail: "บริการด้านอื่นจากทางธนาคาร",
        // },
      ],
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };
  setSelectType = (item) => {
    this.setState({
      selectType: item,
    });
  };

  componentDidMount() {}
  render() {
    let { option, selectType, logoChoiceSize } = this.state;
    return (
      <div className="Main-main w-100 mt-5 ">
        <div className="bg-img set-bg"></div>
        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <div className="flex-all-center my-5" style={{ height: "60vh" }}>
          <div className="border px-2 py-3 fs-6 col-11 h-max-content boder-white rounded-9 bg-white">
            <h5 className="mt-3 text-primary fw-bold text-center">เปลี่ยนหมายเลขบัญชีที่ต้องการให้แจ้งเตือน</h5>
            {option.map((item, index) => {
              return (
                <div
                  key={`saveing-key-${index}`}
                  className="d-flex flex-row"
                  onClick={() => {
                    this.setSelectType(item);
                  }}
                >
                  <div className="px-2  d-flex align-items-center">
                    {/* <Radio className="p-0" checked={selectType.name === item.name} id="Radios1" name="radio-button-demo" inputProps={{ "aria-label": "A" }} color="primary" /> */}
                    {/* <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={selectType.name === item.name} /> */}
                  </div>
                  <div style={{ width: logoChoiceSize }} className="flex-all-center">
                    <img style={{ width: logoChoiceSize }} src={item.img} alt="logo" />
                  </div>
                  <div className="d-flex m-3 flex-column justify-content-center">
                    <h6 className="mb-0 fw-bold text-primary">{item.name}</h6>
                    <div className=" fs-12">{item.detail}</div>
                  </div>
                </div>
              );
            })}
            <div className="flex-all-center mt-4">
              <button
                type="button"
                onClick={() => {
                  this.props.next("Edit", selectType);
                }}
                disabled={!selectType.name}
                className={`btn btn-primary col-6 rounded-5 py-2 fs-14 ${!selectType.name ? "disable-btn" : ""}`}
              >
                ถัดไป
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectOption;
