import React from "react";
import logo from "../../assets/logo.svg";
import calen from "../../assets/calen.png";
import moment from "moment";

class RegisAMobileMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firsttime: {
        idCard: true,
        birthDate: true,
        phoneno: true,
      },
      idCard: "",
      birthDate: null,
      phoneno: "",
      checkUseAMobile: false,
    };
  }

  setuseAMobile = (evt) => {
    let data = !this.state.checkUseAMobile;
    this.setState({
      checkUseAMobile: data,
    });
  };
  setIdCard = (evt) => {
    let { value } = evt.target;
    if (this.state.firsttime.idCard) {
      let val = this.state.firsttime;
      val.idCard = false;
      this.setState({
        firsttime: val,
      });
    }
    const re = /^[0-9\b]+$/;

    if (value.length <= 13 && (value === "" || re.test(value))) {
      this.setState({
        idCard: value,
      });
    }
  };
  setName = (text) => {
    this.props.goTo(text);
  };
  setBirthDate = (evt) => {
    let { value } = evt.target;
    if (this.state.firsttime.birthDate) {
      let val = this.state.firsttime;
      val.birthDate = false;
      this.setState({
        firsttime: val,
      });
    }
    this.setState({
      birthDate: value,
    });
  };
  formatDateEdit = () => {
    if (this.state.birthDate) {
      let split = this.state.birthDate.split("/");
      // console.log(split[2], split[1], split[0]);
      let newDate = new Date(split[2], split[1] - 1, split[0]);
      let formated = moment(newDate).format("YYYY-MM-DD");
      // console.log(formated);
      this.setState({
        birthDate: formated,
      });
    }
  };
  formatDateShow = () => {
    if (this.state.birthDate && this.state.birthDate[0] !== "0") {
      let formated = moment(this.state.birthDate).format("DD/MM/YYYY");
      this.setState({
        birthDate: formated,
      });
    }
  };

  setPhoneno = (evt) => {
    let { value } = evt.target;
    if (this.state.firsttime.phoneno) {
      let val = this.state.firsttime;
      val.phoneno = false;
      this.setState({
        firsttime: val,
      });
    }
    const re = /^[0-9\b]+$/;

    if (value.length <= 10 && (value === "" || re.test(value))) {
      this.setState({
        phoneno: value,
      });
    }
  };
  // setCode = (evt) => {
  //   let { data } = evt.nativeEvent;
  //   if (data) {
  //     if (this.state.code.length < 7) {
  //       let updateData = this.state.code + data.toString();
  //       this.setState({
  //         code: updateData,
  //       });
  //     }
  //   } else {
  //     let spliceData = this.state.code.substr(0, this.state.code.length - 1);
  //     this.setState({
  //       code: spliceData,
  //     });
  //   }
  // };

  componentDidMount() {}
  render() {
    let { idCard, checkUseAMobile, birthDate, phoneno, code, firsttime } = this.state;
    return (
      <div className="RegisAMobileMember-main w-100 pt-5 bg-warning p-3">
        <div className="bg-img"></div>

        <div className="App">
          <img className="col-3" src={logo} alt="logo" />
        </div>
        <div className="border mt-3 p-4 fs-6 rounded-3 rounded-9 bg-white">
          <h5 className="mt-3 text-primary fw-bold text-center">ลงทะเบียนบริการ BAAC Connect</h5>
          <div className="text-center">กรุณากรอกข้อมูล</div>
          {/* <div className="mt-3 fs-14">เลขบัตรประชาชน</div> */}
          <div className={`input-group mt-2 ${idCard.length == 13 ? "mb-3" : ""}`}>
            <input inputMode="numeric" type="text" maxLength="13" className={` form-control rounded-5 ${idCard.length !== 13 && !firsttime.idCard ? "error" : ""}`} placeholder="เลขบัตรประชาชน" aria-label="idcard" onChange={this.setIdCard} value={idCard} />
          </div>
          {idCard.length !== 13 && !firsttime.idCard ? (
            <div className="fs-12 mb-3" style={{ color: "red" }}>
              เลขบัตรประชาชนต้องมี 13 หลัก
            </div>
          ) : (
            ""
          )}
          {/* <div className="form-check mt-2" onChange={this.setuseAMobile}>
            <input className="form-check-input" type="checkbox" value={checkUseAMobile} id="acc" />
            <label className="form-check-label" htmlFor="acc">
              ใช้เบอร์ที่สมัคร A-Mobile
            </label>
          </div> */}
          <div className="mt-4">
            <h6 className="text-primary">กรอกข้อมูลเพื่อลงทะเบียน</h6>
            {/* <div className="mt-3 fs-14">วัน/เดือน/ปีเกิด</div> */}
            <div className={`input-group mt-2 ${birthDate ? "mb-3" : ""}`} style={{ position: "relative" }}>
              <input
                type="text"
                onFocus={(e) => {
                  this.formatDateEdit();
                  e.currentTarget.type = "date";
                  // e.currentTarget.click();
                  e.currentTarget.focus();
                }}
                onBlur={(e) => {
                  e.currentTarget.type = "text";
                  // e.currentTarget.focus();
                  this.formatDateShow();
                }}
                placeholder="วัน/เดือน/ปีเกิด"
                className={` form-control rounded-5 ${!birthDate && !firsttime.birthDate ? "error" : ""}`}
                aria-label="idcard"
                onChange={this.setBirthDate}
                value={birthDate}
              />
              <div style={{ position: "absolute", right: "12px", top: "5px", zIndex: 1000 }}>
                <img style={{ height: "15px" }} src={calen} />
              </div>
              {/* {!birthDate ? <div style={{ position: "absolute", left: "12px", top: "5px", zIndex: 1000 }}>วัน/เดือน/ปีเกิด</div> : ""} */}
            </div>
            {!birthDate && !firsttime.birthDate ? (
              <div className="fs-12 mb-3" style={{ color: "red" }}>
                กรุณาเลือกวันเกิด
              </div>
            ) : (
              ""
            )}
            {/* <div className="mt-3 fs-14">เบอร์โทรศัพท์มือถือ</div> */}
            <div className={`input-group mt-2 ${phoneno.length == 10 ? "mb-3" : ""}`}>
              <input inputMode="numeric" type="text" maxLength="10" className={` form-control rounded-5 ${phoneno.length !== 10 && !firsttime.phoneno ? "error" : ""}`} placeholder="เบอร์โทรศัพท์มือถือ" aria-label="idcard" onChange={this.setPhoneno} value={phoneno} />
            </div>
            {phoneno.length !== 10 && !firsttime.phoneno ? (
              <div className="fs-12" style={{ color: "red" }}>
                เบอร์โทรศัพท์มือถือ 10 หลัก
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mt-3">
            <div>ลงทะเบียนบริการ BAAC Connect ฟรี</div>
            <ul className="mt-2">
              <li>แจ้งเตือนการใช้งานบัญชี</li>
              <li>รับสิทธิประโยชน์และข่าวสารต่าง ๆ</li>
              <li>พร้อมบริการอื่นๆ อีกมากมาย</li>
            </ul>
          </div>
          <div className="p-3 text-primary bg-green fs-14 mt-2 text-center">เพื่อความปลอดภัยในการใช้งานแอปพลิเคชัน LINE ธนาคารแนะนำให้ท่านตั้ง Passcode ในการใช้งาน โดยท่านสามารถตั้ง Passcode ได้ที่เมนู Setting -> Privacy -> Passcode lock</div>
        </div>

        <div className="p-5"></div>
        <div id="footer" className="shadow-lg footer px-2 py-4 d-flex flex-row justify-content-around">
          <button type="button" onClick={() => this.setName("otp7")} disabled={idCard.length !== 13 || !birthDate || phoneno.length !== 10} className={`btn btn-primary py-2 w-45 rounded-5 fs-14 ${idCard.length !== 13 || !birthDate || phoneno.length !== 10 ? "disable-btn" : ""}`}>
            ลงทะเบียน
          </button>
        </div>
      </div>
    );
  }
}

export default RegisAMobileMember;
