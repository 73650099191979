import React from "react";
import accountgreen from "../../../assets/accountgreen.png";
import logo from "../../../assets/logo.svg";
import SelectOption from "./SelectOption";
import Checkotp6 from "../../otp/Checkotp6";
import Edit from "./Edit";
import Overall from "./Overall";

class EditServiceMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: "select",
      selectType: {},
      selectAccount: {},
      loading: false,
    };
  }

  setName = (text) => {
    this.props.goTo(text);
  };
  setstep = (item, setectData) => {
    this.setState({
      step: item,
      selectType: setectData,
    });
  };
  setSelectAccount = (item) => {
    this.setState({
      step: "overall",
      selectAccount: item,
    });
  };
  nextToOtp = () => {
    this.setState({
      step: "checkotp",
    });
  };
  back = () => {
    this.setState({
      step: "select",
    });
  };
  submit = async () => {
    if ((await window.liff.isInClient()) && !this.state.loading) {
      this.setState({
        loading: true,
      });
      // const message = "ท่านได้เปลี่ยนแปลงบัญชีรับการแจ้งเตือนเรียบร้อย";
      const message = "เปลี่ยนแปลงบัญชีรับการแจ้งเตือนเรียบร้อย";
      try {
        await window.liff.sendMessages([
          {
            text: message,
            type: "text",
          },
        ]);
        this.setState({
          loading: false,
        });
        window.liff.closeWindow();
      } catch (error) {
        this.setState({
          loading: false,
        });
        alert(JSON.stringify(error));
        alert(JSON.stringify(error.message));
      }
    }
  };

  componentDidMount() {}
  render() {
    let { step, selectType, selectAccount } = this.state;
    return <div className="EditServiceMain-main w-100 mt-5 ">{step === "select" ? <SelectOption next={this.setstep} /> : step === "Edit" ? <Edit selectType={selectType.name} next={this.setSelectAccount} back={this.back} /> : step === "overall" ? <Overall next={this.nextToOtp} back={this.back} saveing={selectAccount} /> : step === "checkotp" ? <Checkotp6 next={this.submit} back={this.back} /> : ""}</div>;
  }
}

export default EditServiceMain;
